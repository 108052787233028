<template>
  <v-container>
    <h3> {{$t("message.title-update-merchant")}}</h3>
    <v-col class="pr-2 pb-2" cols="12">
      <form>
        <v-row>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span> {{$t("message.label-merchant")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-text-field
              outlined
              v-model="updateData.merchant_name"
              :error-messages="merchant_nameErrors"
              @input="$v.updateData.merchant_name.$touch()"
              @blur="$v.updateData.merchant_name.$touch()"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span>{{$t("message.label-phone")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-text-field
              outlined
              v-model="updateData.phone"
              :error-messages="phoneErrors"
              @input="$v.updateData.phone.$touch()"
              @blur="$v.updateData.phone.$touch()"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span>{{$t("message.label-fee")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-text-field
              type="number"
              :error-messages="feeErrors"
              @input="$v.updateData.fee.$touch()"
              @blur="$v.updateData.fee.$touch()"
              outlined
              v-model="updateData.fee"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span>{{$t("message.label-status")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-select
              type="number"
              v-model="updateData.is_active"
              :items="is_active"
              outlined
              item-text="is_active"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
        <div class="d-flex flex-row-reverse mt-3 me-0" tile>
          <div class="m-2">
            <v-btn color="primary" @click.prevent="updateApproveList">
              {{$t("message.btn-update")}}
            </v-btn>
          </div>
          <div>
            <v-btn color="grey" class="m-2" @click="$router.go(-1)">
              {{$t("message.btn-back")}}
            </v-btn>
          </div>
        </div>
      </form>
    </v-col>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  numeric,
  required,
  decimal
} from "vuelidate/lib/validators";
export default {
  name: "UpdateMerchant",
  mixins: [validationMixin],
  validations: {
    updateData: {
      fee: { numeric, decimal },
      merchant_name: { required },
      phone: { numeric, required }
    }
  },
  data: () => ({
    updateData: {
      merchant_name: "",
      phone: "",
      is_active: "",
      fee: ""
    },
    is_active: [
      { id: 0, is_active: 'インアクティブ' },
      { id: 1, is_active: 'アクティブ' },
    ],
  }),
  methods: {
    updateApproveList() {
      this.$v.$touch()

      this.$store.dispatch("approve_module/updateMerchant", {
        id: this.$route.params.id,
        data: this.updateData,
      });
    },
    async dispatchDetailApprove() {
      let id = this.$route.params.id;
      await this.$store.dispatch("approve_module/fetchDetailApprove", id);

      let detailMerchant = this.$store.state.approve_module.resultDetail;

      this.updateData.merchant_name = detailMerchant.merchant_name;
      this.updateData.phone = detailMerchant.phone;
      this.updateData.is_active = detailMerchant.is_active;
      this.updateData.fee = detailMerchant.fee
    },
  },
  computed: {
    merchant_nameErrors() {
      const errors = [];
      if (!this.$v.updateData.merchant_name.$dirty) return errors;
      !this.$v.updateData.merchant_name.required &&
        errors.push(this.$t("message.err-msg-merchant_name"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.updateData.phone.$dirty) return errors;
      !this.$v.updateData.phone.required &&
        errors.push(this.$t("message.err-msg-phone"));
      !this.$v.updateData.phone.numeric &&
        errors.push(this.$t("message.err-msg-phone-numeric"));

      return errors;
    },
    feeErrors() {
      const errors = [];
      if (!this.$v.updateData.fee.$dirty) return errors;
      // !this.$v.updateData.fee.numeric &&
      //   errors.push(this.$t("message.err-msg-fee-numeric"));
        //  !this.$v.updateData.phone.decimal &&
        // errors.push(this.$t("kiw"));
      // if(this.updateData.fee <= 0) {
      //   errors.push(this.$t("message.err-msg-fee-moreThan"))
      // }
      // !this.$v.updateData.fee.required &&
      //   errors.push(this.$t("message.err-msg-price"));
      return errors;
    },
  },
  created() {
    this.dispatchDetailApprove();
  },
};
</script>

<style></style>
